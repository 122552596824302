const block = (container) => {
  const load = async (js) => {
    await new Promise((resolve) => {
      var script = document.createElement('script')
      script.setAttribute('src', '/assets/pageBuilder/blocks/' + js + '.js')
      script.setAttribute('type', 'text/javascript')
      script.onload = () => {
        resolve()
      }
      container.appendChild(script)
    })
  }

  return { load }
}

module.exports = (settings) => {
  const save = {
    blocks: []
  }
  const setCallbackFunctions = () => {
    window.onsave = settings.onSave
    window.onchanged = settings.onChange
    window.onclose = settings.onClose
  }
  const loadCSS = () => {
    const css = document.createElement('link')
    css.setAttribute('href', '/assets/pageBuilder/css/app.css')
    css.setAttribute('rel', 'stylesheet')
    settings.container.appendChild(css)
  }
  const loadScript = async () => {
    await new Promise((resolve) => {
      var script = document.createElement('script')
      script.setAttribute('src', '/assets/pageBuilder/js/app.js')
      script.setAttribute('type', 'text/javascript')
      script.onload = () => {
        resolve()
      }
      settings.container.appendChild(script)
    })
  }
  const loadBlocks = async () => {
    for (var i = 0; i < settings.blocks.length; i++) {
      await block(settings.container).load(settings.blocks[i])
      window['block_' + settings.blocks[i]].img =
        '/assets/pageBuilder/blocks/img/' +
        window['block_' + settings.blocks[i]].img
      save.blocks.push(window['block_' + settings.blocks[i]])
    }

    window.blocks = save.blocks
    window.layout = settings.layout
  }
  const setPagebuilderContainer = () => {
    const pageBuilder = document.createElement('flix-pagebuilder')
    pageBuilder.setAttribute('layout', 'layout')
    pageBuilder.setAttribute('blocks', 'blocks')
    pageBuilder.setAttribute('language', settings.language)
    pageBuilder.setAttribute('onclose', 'onclose')
    pageBuilder.setAttribute('onsave', 'onsave')
    pageBuilder.setAttribute('onchanged', 'onchanged')
    settings.container.appendChild(pageBuilder)
  }
  const run = async () => {
    setCallbackFunctions()
    settings.container.innerHTML = ''
    loadCSS()
    await loadScript()
    await loadBlocks()
    setPagebuilderContainer()
  }
  return { run }
}
