<template>
  <div>
    <div class='pageBuilder'></div>
  </div>
</template>
<script>
import { loadPageBuilder } from '@/components/pageBuilder/load.js'
export default {
  components: {},
  props: {
    onClose: Function,
    edit: String
  },
  data () {
    return {
      data: false,
      services: false,
      layout: JSON.stringify([])
    }
  },
  methods: {
    setSave (data) {
      this.layout = atob(data)

      var send = {
        user: this.$store.getters.user.md5_id,
        ID: this.edit,
        data: JSON.parse(atob(data)),
        lang: 'de'
      }
      this.$flix_post({
        url: 'pagebuilder/mainpage/save',
        data: send,
        callback: function (ret) { window.console.log(ret); this.onClose() }.bind(this)
      })
    },
    loadPageBuilder () {
      var pb = new loadPageBuilder()
      pb.settings = {
        language: this.$i18n.locale,
        blocks: [14, 1, 6, 3, 5, 34, 4, 2, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
        onClose: function () { this.onClose() }.bind(this),
        onSave: function (blocks) { this.setSave(btoa(JSON.stringify(blocks))) }.bind(this),
        onChange: function (blocks) { },
        layout: JSON.parse(
          this.layout
        )
      }
      pb.run()
    }
  },
  mounted () {
    this.$store.dispatch('getLayout', {
      ID: this.edit,
      callback: function (layout) {
        if (layout) {
          this.layout = layout
        }
        this.loadPageBuilder()
      }.bind(this)
    })
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
